<!--四大文件管理-->
<template>
  <page-container title="违约情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>

            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开-->
<!--                <a-icon :type="showAdvanced?'up':'down'"></a-icon>-->
<!--              </a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">四大文件列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="contract" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.contract!==''&&record.contract" class="text" @click.stop="downloadFileClick(record.contract)">
                {{ record.contract.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="convention" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.convention!==''&&record.convention" class="text" @click.stop="downloadFileClick(record.convention)">
                {{ record.convention.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="manual" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.manual!==''&&record.manual" class="text" @click.stop="downloadFileClick(record.manual)">
                {{ record.manual.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="requirement" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.requirement!==''&&record.requirement" class="text" @click.stop="downloadFileClick(record.requirement)">
                {{ record.requirement.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>

          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改违约页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="物业管理合同附件" prop="contrac">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadFourFilesData" action="/upload" :file-list="contractList" @change="contractUploaded"
                    accept=".doc,.docx,.pdf" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="业主公约附件" prop="convention">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadFourFilesData" action="/upload" :file-list="conventionList" @change="conventionUploaded"
                    accept=".doc,.docx,.pdf" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="业主手册附件" prop="manual">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadFourFilesData" action="/upload" :file-list="manualList" @change="manualUploaded"
                    accept=".doc,.docx,.pdf" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="装修管理规定附件" prop="requirement">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadFourFilesData" action="/upload" :file-list="requirementList" @change="requirementUploaded"
                    accept=".doc,.docx,.pdf" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceFileLookup,
  getFileLookupListByCondition,
  deleteOneFileLookup,
  editFileLookup
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {mapGetters} from "vuex";
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'fileLookup',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,

      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '四大文件查阅',
          path: ''
        }
      ],
      queryParams: {
        contract_path: '',
        convention_path: '',
        manual_path: '',
        requirement_path: '',
        userdepid:'',
        selectDeptList:[]
      },
      //服务内容 收费类型	收费标准	收费形式	服务标准
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '物业管理合同',
          dataIndex: 'contract',
          key: 'contract',
          scopedSlots: { customRender: 'contract' }
        },
        {
          title: '业主公约',
          dataIndex: 'convention',
          key: 'convention',
          scopedSlots: { customRender: 'convention' }
        },
        {
          title: '业主手册',
          dataIndex: 'manual',
          key: 'manual',
          scopedSlots: { customRender: 'manual' }
        },
        {
          title: '装修管理规定',
          dataIndex: 'requirement',
          key: 'requirement',
          scopedSlots: { customRender: 'requirement' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      contractList:[],
      conventionList:[],
      manualList:[],
      requirementList:[],

      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        contract_path: '',
        convention_path: '',
        manual_path: '',
        requirement_path: '',

        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        // service_content: [{required: true, message: '请输入服务内容'}],
        // fees_type: [{required: true, message: '请选择收费类型'}],
        // fees_standard: [{required: true, message: '请输入收费标准,按照物业服务合同填写，如同一小区存在多个标准的应一一枚举'}],
        // fees_form: [{required: true, message: '请选择收费形式'}],
        // service_standard: [{required: true, message: '请输入服务标准，按物业服务合同约定'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedFileLookup:null
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadFourFilesData']),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        // userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      console.log(params)
      getFileLookupListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item.map(item => ({
            ...item,
            service_standard_annex: item.hasOwnProperty('annex')?(item.annex===''?'0':'1'):'0',
            annex:item.hasOwnProperty('annex') ? item.annex : '',
          })):[]
          this.pagination.total = res.count;
        }
        console.log(this.tableData)
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;

      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0

        this.modalForm.contract_path=''
        this.modalForm.convention_path=''
        this.modalForm.manual_path=''
        this.modalForm.requirement_path=''
        this.conventionList=[]
        this.contractList=[]
        this.manualList=[]
        this.requirementList=[]

        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id=record.id


        this.modalForm.contract_path=record.contract
        this.modalForm.convention_path=record.convention
        this.modalForm.manual_path=record.manual
        this.modalForm.requirement_path=record.requirement

        const contract_file_name = record.contract!==''&&record.contract?record.contract.split("/").pop(): '';
        this.contractList = contract_file_name!==''?[{
          uid: 'filenameuid',
          name: contract_file_name,
          status: 'done',
          url: record.contract,
        }]:[]

        const cconvention_file_name = record.convention!==''&&record.convention?record.convention.split("/").pop():'';
        this.conventionList = cconvention_file_name!==''?[{
          uid: 'filenameuid',
          name: cconvention_file_name,
          status: 'done',
          url: record.convention,
        }]:[]

        const manual_file_name = record.manual!==''&&record.manual?record.manual.split("/").pop():'';
        this.manualList = manual_file_name!==''?[{
          uid: 'filenameuid',
          name: manual_file_name,
          status: 'done',
          url: record.manual,
        }]:[]

        const requirement_file_name = record.requirement!==''&&record.requirement?record.requirement.split("/").pop():'';
        this.requirementList = requirement_file_name!==''?[{
          uid: 'filenameuid',
          name: requirement_file_name,
          status: 'done',
          url: record.requirement,
        }]:[]

        this.modalForm.userdepid = record.userdep_id
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(fileLookup_id) {
      if (fileLookup_id) {
        let params = {
          fileLookup_id
        };
        deleteOneFileLookup(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {

        contract:this.modalForm.contract_path,
        convention:this.modalForm.convention_path,
        manual:this.modalForm.manual_path,
        requirement:this.modalForm.requirement_path,

        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceFileLookup(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editFileLookup(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedFileLookup = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedFileLookup)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedFileLookup)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedFileLookup)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedFileLookup)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    contractUploaded(info) {
      console.log(info)
      let fileList = [...info.fileList];
      console.log(fileList)
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.contract_path = file.response.urlPath;
        }
        return file;
      });
      this.contractList = fileList;
    },
    conventionUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.convention_path = file.response.urlPath;
        }
        return file;
      });
      this.conventionList = fileList;
    },
    manualUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.manual_path = file.response.urlPath;
        }
        return file;
      });
      this.manualList = fileList;
    },
    requirementUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.requirement_path = file.response.urlPath;
        }
        return file;
      });
      this.requirementList = fileList;
    },
    downloadFileClick(path){
      if(path!==''){
        const pageUrl = window.location.origin;
        const name = path.split("/").pop();
        const download_path=pageUrl+"/"+path;
        let a = document.createElement('a');
        a.href = download_path;
        a.download = name;
        a.click();
      }else{
        this.$message.error("文件为空！");
      }
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是doc/docx/pdf");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.service_standard_annex-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
</style>